import React, { useCallback } from 'react';
import { Button, Flex, Link, Loading, Modal, Spacer } from '@components/common';
import { Heading, Paragraph } from '@components/typography';
import { UPSELL_STATE, useOrderContext } from '@utils/context/Order';
import { slideFromBottom } from '@utils/motions';
import { getPairCareModalButtonCopy, getPairCareModalCopy } from '@utils/constants/account';
import { capitalizeEachWord, useTranslation } from '@utils/index';
import styles from './AddPairCareModal.module.scss';

const getTranslatedText = translator => {
	return {
		gratefulPhrasePariCarse: translator('grateful-phrase-PariCarse'),
		contactCustomerService: translator('contact-customer-service'),
		sentExclamation: translator('sent-exclamation'),
		closeText: capitalizeEachWord(translator('close')),
		plusAdd: translator('plus-add'),
	};
};

const AddPairCareModal = () => {
	const { addVariantToOrder, isMutating, pairCareUpsellState, order, pairCareVariant } = useOrderContext();
	const { translator, locale } = useTranslation();
	const translations = getTranslatedText(translator);

	const attemptOrderEdit = useCallback(async () => {
		await addVariantToOrder({
			orderId: order.shopify_id,
			variantId: pairCareVariant.id,
		});
	}, [addVariantToOrder, order.shopify_id, pairCareVariant.id]);

	return (
		<Modal>
			<Modal.Content className={styles.container} customAnimation={slideFromBottom}>
				<Flex column fullHeight justify='between'>
					<Flex column align='start' gap={2}>
						<Heading tag='h5'>
							{pairCareUpsellState === UPSELL_STATE.PENDING ? translations.gratefulPhrasePariCarse : 'PairCare'}
						</Heading>
						{isMutating ? (
							<Loading />
						) : (
							<>
								<Paragraph>{getPairCareModalCopy(pairCareUpsellState, locale).LINE_1}</Paragraph>
								<Spacer size={'1.6rem'} />
								<Paragraph>
									{getPairCareModalCopy(pairCareUpsellState, locale).LINE_2}
									{pairCareUpsellState === UPSELL_STATE.PENDING && (
										<Link small href='https://paireyewearhelp.zendesk.com/hc/en-us/requests/new'>
											{translations.contactCustomerService}.
										</Link>
									)}
								</Paragraph>
							</>
						)}
					</Flex>
					<Flex fullWidth gap={3}>
						<Modal.Close asChild>
							<Button
								fullWidth
								color='white'
								label={
									pairCareUpsellState === UPSELL_STATE.PENDING
										? translations.sentExclamation
										: translations.closeText
								}
								disabled={pairCareUpsellState === UPSELL_STATE.PENDING}
							/>
						</Modal.Close>
						{pairCareUpsellState === UPSELL_STATE.AVAILABLE && (
							<Button
								onClick={() => attemptOrderEdit()}
								fullWidth
								label={translations.plusAdd}
								withPrice
								price={pairCareVariant.price.amount}
								data-add-paircare-upsell
							/>
						)}
					</Flex>
				</Flex>
			</Modal.Content>
			<Modal.Trigger
				asChild
				disabled={
					isMutating ||
					pairCareUpsellState === UPSELL_STATE.INELIGABLE ||
					pairCareUpsellState === UPSELL_STATE.PURCHASED
				}
			>
				<Button
					extraClasses={styles.button}
					label={getPairCareModalButtonCopy(pairCareUpsellState, locale)}
					data-open-paircare-modal={pairCareUpsellState === UPSELL_STATE.PENDING ? 'pending' : 'available'}
					color={pairCareUpsellState === UPSELL_STATE.PENDING ? 'white' : 'green'}
				/>
			</Modal.Trigger>
		</Modal>
	);
};

export default AddPairCareModal;
